import dayjs from 'dayjs';

import { getWordpress } from 'PublicAPI';

const getArchive = async () => getWordpress('/serve_archive/archive');
const getBlogPosts = async (params) => getWordpress(`/wp/v2/posts?_embed&${params}`);

const getExcerpt = (post) => {
  let exc = post.excerpt.rendered;
  if (!exc) {
    return post.content.rendered;
  }

  const { pathname, search } = new URL(post.link);

  exc = exc.replace(/\n<p\sclass.*link-more.*p>\n/g, '');
  exc += `\n<p class="link-more"><a href="${pathname}${search ?? ''}" class="mw-link mw-link-blue mw-link-small mw-link-copy">Continue reading</a></p>\n`;
  return exc;
};

export const fetchBlogData = async (originalUrl = '') => {
  const urlParts = (Array.isArray(originalUrl)
    ? originalUrl
    : originalUrl.split('/').filter((v) => v))
    .filter((v) => v !== 'blog');
  const isNumber = (v) => !Number.isNaN(v) && Number.isInteger(parseInt(v));

  let params = {};

  const wp = {
    archive: null,
    content: null,
    meta: {
      desc: null,
      title: null,
    },
    posts: [],
    showFull: false,
    title: null,
  };

  if (urlParts.length === 0) {
    // Base view
    params.per_page = 10;
  } else if (urlParts.length < 4) {
    if (!urlParts.slice(0, 3).every(isNumber)) {
      return;
    }

    let period = 'year';

    const formatDate = (date, end) => {
      date = +date;
      return (!Number.isNaN(date) && date > 0 && date <= end) ? date.toString().padStart(2, 0) : '01';
    };

    const daysInMonth = dayjs(`${+urlParts[0] || dayjs().year()}-${formatDate(urlParts[1], 12)}`, 'YYYY-MM').daysInMonth();

    if (urlParts[2]) {
      /**
       * Stop the server from falling over on `https://motorway.co.uk/blog/2017/02/31`
       * This is also dealt with in the router
       */
      if (!Number.isNaN(+urlParts[2]) && +urlParts[2] > daysInMonth) {
        urlParts[2] = daysInMonth;
      }

      period = 'day';
    } else if (urlParts[1]) {
      period = 'month';
    }

    const date = dayjs.utc([
      +urlParts[0] || dayjs().year(), formatDate(urlParts[1], 12), formatDate(urlParts[2], 31),
    ].join('-'));

    const startDate = date.startOf(period).toISOString().slice(0, -5);
    const endDate = date.endOf(period).toISOString().slice(0, -5);

    params = {
      after: startDate,
      before: endDate,
    };
  } else {
    // Single post
    params.slug = urlParts[urlParts.length - 1];
  }

  const archive = [];
  const [body, aBody] = (await Promise.allSettled([
    getBlogPosts(Object.keys(params).map((x) => `${x}=${params[x]}`).join('&')),
    getArchive(),
  ])).map(({ value }) => value);

  if (!body.length) {
    return wp; // eslint-disable-line consistent-return
  }

  if (params.slug) {
    wp.showFull = true;
  }

  wp.posts = body.map((p) => {
    const date = new Date(p.date);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    if (params.slug) {
      wp.meta = {
        desc: p?.yoast?.yoast_wpseo_metadesc,
        title: p?.yoast?.yoast_wpseo_title,
      };
    }

    /* eslint-disable camelcase, no-underscore-dangle */
    const {
      _links,
      avatar_urls,
      link,
      url,
      yoast_head,
      yoast_head_json,
      ...author
    } = p._embedded.author[0];

    return {
      author,
      content: p.content.rendered,
      date: dayjs(p.date).format('Do MMMM YYYY'),
      excerpt: getExcerpt(p),
      slug: params.slug ? null : `/blog/${year}/${month}/${day}/${p.slug}`,
      title: p.title.rendered,
    };
    /* eslint-enable camelcase, no-underscore-dangle */
  });

  if (aBody && aBody.length > 0) {
    aBody.forEach((x) => {
      const date = dayjs(x, 'MMMM YYYY');
      archive.push({
        label: x,
        route: `/blog/${date.year()}/${(date.month() + 1).toString().padStart(2, '0')}`,
      });
    });

    wp.archive = archive;
  }

  return wp; // eslint-disable-line consistent-return
};
