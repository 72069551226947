/* eslint-disable camelcase */

import { WordpressPageData } from './page';

export type WordpressGuideData = WordpressPageData & {
  parent: 32;
};

export const GUIDE_SUMMARY_KEYS = ['id', 'title', 'date', 'modified', 'excerpt', 'link'] as const;

export type WordpressGuideSummaryData = Pick<
WordpressGuideData,
typeof GUIDE_SUMMARY_KEYS[number]
>;
